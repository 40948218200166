
$(document).ready(function() {
    $('.block_newsletter .submit_newsletter').prop("disabled", true);
    $('.block_newsletter .submit_newsletter').css('cursor',"not-allowed");
    $('.block_newsletter .valid_newsletter').click(function() {
        if ($(this).is(':checked')) {
            $('.block_newsletter .submit_newsletter').prop("disabled", false);
            $('.block_newsletter .submit_newsletter').css('cursor',"pointer");
        } else {
            if ($('.block_newsletter .valid_newsletter').filter(':checked').length < 1){
                $('.block_newsletter .submit_newsletter').attr('disabled',true);
                $('.block_newsletter .submit_newsletter').css('cursor',"not-allowed");
            }
        }
    });
});